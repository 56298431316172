import { Card, CardContent, CardHeader, Stack } from "@mui/material";
import RowItem from "./RowItem";

const OrganizationInfo = ({ organization }) => {
  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        title="معلومات التاجر"
        titleTypographyProps={{ variant: "h6" }}
        style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)", fontWeight: "bold" }}
      />
      <CardContent>
        {organization && (
          <Stack>
            <RowItem title="اسم الشركة / التاجر:" value={organization.name} />
            <RowItem title="تلفون:" value={organization.phone} />
            <RowItem title="العنوان:" value={organization.address} />
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

export default OrganizationInfo;
