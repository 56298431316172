import { CustomerAPI } from "api/customer";

const CustomerService = {
  findCustomer: function (searchTerm, pageSize = null, pageNumber = null) {
    return CustomerAPI.findCustomer({ searchTerm, pageSize, pageNumber });
  },
  createNewCustomer: function (firstName, middleName, lastName, mobile, email, address, gender) {
    return CustomerAPI.createNewCustomer({
      firstName,
      middleName,
      lastName,
      mobile,
      email,
      address: address.address1,
      areaCode: address.areaCode,
      gender,
    });
  },
  getCustomerList: function (filters) {
    return CustomerAPI.getCustomerList(filters);
  },
};

export default CustomerService;
