import APIClient from "./axios.js";
import {
  CREATE_NEW_PACKAGE_API_URL,
  GET_ACTIVE_PACKAGE_LIST_API_URL,
  GET_PACKAGE_HISTORY_LIST_API_URL,
  UPDATE_PACKAGE_API_URL,
  GET_PACKAGE_DETAILS_API_URL,
  ASSIGN_DRIVER_TO_PACKAGE_API_URL,
  GET_PACKAGE_LIST_API_URL,
  LINK_PACKAGE_TO_EXTERNAL_SYSTEM_API_URL,
  PRINT_PACKAGE_BILL_API_URL,
  GET_PACKAGE_SUMMARY_URL,
  UPDATE_PACKAGE_STATUS_API_URL,
} from "../variables/constant";

export const PackageAPI = {
  createNewPackage: function (data) {
    return APIClient.post(CREATE_NEW_PACKAGE_API_URL, data);
  },
  getActivePackagesList: function () {
    return APIClient.get(GET_ACTIVE_PACKAGE_LIST_API_URL);
  },
  getPackagesList: function () {
    return APIClient.get(GET_PACKAGE_LIST_API_URL);
  },
  getPackageHistoryList: function (data) {
    return APIClient.get(GET_PACKAGE_HISTORY_LIST_API_URL, { params: data });
  },
  updatePackage: function (packageId, data) {
    return APIClient.post(UPDATE_PACKAGE_API_URL.replace("{packageId}", packageId), data);
  },
  updatePackagesStatus: function (packageIds, status) {
    return APIClient.post(UPDATE_PACKAGE_STATUS_API_URL, { packageIds, status });
  },
  getPackageDetails: function (packageId) {
    return APIClient.get(GET_PACKAGE_DETAILS_API_URL.replace("{packageId}", packageId));
  },
  assigneDriverToPackage: function (packageId, data) {
    return APIClient.post(ASSIGN_DRIVER_TO_PACKAGE_API_URL.replace("{packageId}", packageId), data);
  },
  linkPackageToExternalSystem: function (packageId, data) {
    return APIClient.post(LINK_PACKAGE_TO_EXTERNAL_SYSTEM_API_URL.replace("{packageId}", packageId), data);
  },
  printBill: function (packageIds) {
    return APIClient.post(PRINT_PACKAGE_BILL_API_URL, { packageIds: packageIds });
  },
  getPackageSummary: function () {
    return APIClient.get(GET_PACKAGE_SUMMARY_URL);
  },
};
