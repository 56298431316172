import { Stack, Typography } from "@mui/material";

const RowItem = ({ title, value }) => {
  return (
    <Stack direction="row" justifyContent="space-between" style={{ padding: 5 }}>
      <Typography color="#525f7f" style={{ fontWeight: "bold" }}>
        {title}
      </Typography>
      <Typography color="#525f7f" style={{ fontWeight: "bold" }}>
        {value}
      </Typography>
    </Stack>
  );
};

export default RowItem;
