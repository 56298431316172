import { useEffect, useRef, useState } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MRT_Localization_AR } from "material-react-table/locales/ar/index.js";
import { ThemeProvider, createTheme, useTheme } from "@mui/material";
import { arSA } from "@mui/material/locale";
import CollectionTableColumns from "./CollectionTableColumns";
import CollectionTableTopBar from "./CollectionTableTopBar";

const Table = (props) => {
  const { data, totalCollections } = props;

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  useEffect(() => {
    //when the pagination state changes
    refreshData();
  }, [pagination.pageIndex, pagination.pageSize]);

  const refreshData = () => {
    props.onDataChange({ pageNumber: pagination.pageIndex, pageSize: pagination.pageSize });
  };

  const table = useMaterialReactTable({
    columns: CollectionTableColumns(),
    data,
    pageIndex: pagination.pageNumber,
    enableColumnResizing: true,
    columnResizeDirection: "rtl",
    enableRowSelection: true,
    initialState: {
      showColumnFilters: false,
      columnPinning: { right: ["mrt-row-actions"] },
      pagination: { pageSize: 25, pageIndex: 0 },
    },
    localization: { ...MRT_Localization_AR },
    enableFullScreenToggle: true,
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    enableSorting: false,
    enablePagination: true,
    manualPagination: true,
    positionToolbarAlertBanner: "head-overlay",
    enableColumnFilters: false,
    rowCount: totalCollections,
    onPaginationChange: setPagination,
    state: { pagination }, //pass the pagination state to the table
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 100], // Set the possible page sizes
    },
    muiTableBodyCellProps: {
      align: "right", // Set alignment for all table body cells
    },
    renderTopToolbar: CollectionTableTopBar,
  });

  return <MaterialReactTable table={table} />;
};

const CollectionTable = (props) => {
  const theme = useTheme(); //replace with your theme/createTheme

  // define direction for table to solve pagination Icons is in wrong direction
  const direction = { direction: "rtl" };

  return (
    //Setting Material UI locale as best practice to result in better accessibility
    <ThemeProvider theme={createTheme(theme, arSA, direction)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Table data={props.data} totalCollections={props.totalCollections} onDataChange={props.onDataChange} />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default CollectionTable;
