import { Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import UtilityService from "service/utility";
import { fCurrency } from "utils/format-number";
import RowItem from "./RowItem";

const PaymentInfo = ({ collection }) => {
  const CustomHeader = () => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)", padding: 16 }}>
        <Typography style={{ fontWeight: "bold" }}>تفاصيل الدفع</Typography>
        <Typography>{UtilityService.renderStatusMessage("COLLECTION", collection.status)}</Typography>
      </Stack>
    );
  };

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader component={CustomHeader} />
      <CardContent>
        <Stack>
          <RowItem title="القيمة:" value={fCurrency(collection.amount)} />

          {collection.paymentType && <RowItem title="طريقة الدفع:" value={collection.paymentType} />}

          {collection.paymentMethod && <RowItem title="طريقة التحصيل:" value={collection.paymentMethod} />}

          {collection.paymentDate && <RowItem title="تاريخ الدفع:" value={collection.paymentDate} />}

          {collection.referenceNumber && <RowItem title="الرقم المرجعي:" value={collection.referenceNumber} />}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default PaymentInfo;
