import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Slide from "@mui/material/Slide";
import { useBoolean } from "hooks/useBoolean";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import OrganizationService from "service/organization";
import PackageService from "service/package";
import UtilityService from "service/utility";
import * as Yup from "yup";
import Actions from "./Actions";
import CustomerInfo from "./CustomerInfo";
import OrganizationInfo from "./OrganizationInfo";
import PackageDetails from "./PackageDetails";
import PaymentInfo from "./PaymentInfo";

// Define a Yup validation schema
const validationSchema = Yup.object({
  customerName: Yup.string().required("Customer Name is required"),
  branchId: Yup.string().required("Branch is required"),
  mobile1: Yup.string()
    .required("Mobile is required") // Field is mandatory
    .matches(/^05\d{8}$/, "Mobile number must match 05XXXXXXXX format"),
  areaCode: Yup.string().required("Address is required"),
  address: Yup.string().required("Address is required"),
  totalCollection: Yup.number().positive("Amount must be number").required("Amount is required"),
  paymentMethod: Yup.string().required("Payment Method is required"),
  collectionMethod: Yup.string().required("Collection Method is required"),
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const defaultValues = {
  customerName: "",
  customerNote: "",
  mobile1: "",
  mobile2: "",
  address: "",
  areaCode: "",
  paymentMethod: "COD",
  totalCollection: "",
  collectionMethod: "CASH",
  quantity: "1",
  weight: "0",
  note: "",
  description: "",
  brackable: false,
  needCooling: false,
  needPackaging: false,
  allowOpen: false,
  dangerous: false,
  referenceNumber: "",
  setviceType: "NORMAL",
  deliveryDate: "",
  branchId: null,
};

const CreateNewPackageModal = ({ onPackageCreated }) => {
  const methods = useForm({ defaultValues, resolver: yupResolver(validationSchema) });

  const { handleSubmit, setValue, reset } = methods;

  const [organization, setOrganization] = useState();

  const [errorMessage, setErrorMessage] = useState("");

  const dialog = useBoolean();

  const onSubmit = (data) => {
    setErrorMessage("");
    console.log(data);
    PackageService.createNewPackage(data)
      .then(() => {
        onPackageCreated();
        dialog.onFalse();
      })
      .catch((error) => {
        setErrorMessage("حدث خطا اثناء حفظ البيانات, يرجى المحاولة مرة اخرى");
      });
  };

  const openModal = () => {
    dialog.onTrue();

    reset();

    if (!UtilityService.isAdmin()) {
      OrganizationService.getCurrentOrganization(true).then((res) => {
        const activeBranchList = res.data.branchList.filter((b) => b.status);
        setOrganization(organization);
        setValue("branchId", activeBranchList[0].id);
      });
    }
    if (UtilityService.isAdmin()) {
      setOrganization(null);
    }
  };

  const onBranchChange = (branch) => {
    setValue("branchId", branch.id);
  };

  const onOrganizationChange = (org) => {
    setOrganization(org);
    const branch = org?.branchList?.filter((b) => b.status)[0];
    setValue("branchId", branch.id);
  };

  return (
    <div>
      <Button color="success" variant="contained" onClick={openModal} size="sm">
        <i className="fas fa-plus"></i> طرد جديد
      </Button>

      <Dialog
        open={dialog.value}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="lg"
        onClose={dialog.onFalse}>
        <DialogContent style={{ padding: 0 }}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body p-0">
                <Grid container style={{ margin: 0, overflow: "auto" }}>
                  <Grid item xs={12} md={6} style={{ padding: 20 }}>
                    <OrganizationInfo
                      showError={!!errorMessage}
                      organization={organization}
                      onOrganizationChange={onOrganizationChange}
                      onBranchChange={onBranchChange}
                    />

                    <CustomerInfo />

                    <PaymentInfo showError={!!errorMessage} />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ padding: 20, backgroundColor: "#fff6e9", display: "flex", flexDirection: "column" }}>
                    <PackageDetails showError={!!errorMessage} />

                    <Actions style={{ marginTop: "auto" }} onCancel={dialog.onFalse} errorMessage={errorMessage} />
                  </Grid>
                </Grid>
              </div>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateNewPackageModal;
