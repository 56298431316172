import { Box, Card, Typography } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import UtilityService from "service/utility";
import { PACKAGE_STATUS_LIST } from "variables/constant";

const PackageStatisticChartWidget = ({ data, colors, totalPackage }) => {
  const chartData = PACKAGE_STATUS_LIST.map((status) => {
    return {
      id: status,
      value: data.find((item) => item.status === status)?.count || 0,
      label: UtilityService.renderStatusMessage("PACKAGE", status, false, true),
    };
  });

  return (
    <Card
      sx={{
        p: 3,
        cursor: "pointer",
        height: { md: "100%", xs: 400 },
        width: "100%",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        "&:hover": {
          transform: "scale(1.03)", // Scale up on hover
          boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.3)", // Stronger shadow on hover
        },
      }}>
      <Typography variant="h6" textAlign="center" sx={{ height: 20 }}>
        عدد الطرود: {totalPackage}
      </Typography>
      <Box sx={{ height: "calc(100% - 20px)" }}>
        <PieChart slotProps={{ legend: { hidden: true } }} colors={colors} series={[{ data: chartData, cx: "30%" }]} />
      </Box>
    </Card>
  );
};

export default PackageStatisticChartWidget;
