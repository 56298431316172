import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useFormContext, Controller } from "react-hook-form";

const PAYMENT_TYPE_OPTIONS = [
  {
    value: "COD",
    label: "التحصيل عند الاستلام",
  },
  {
    value: "PREPAID",
    label: "مدفوع مسبقا",
  },
];

const PAYMENT_MEtHOD_OPTIONS = [
  {
    value: "CASH",
    label: "التحصيل نقدا",
  },
  {
    value: "CHECK",
    label: "شيك بنكي",
  },
  {
    value: "BANK_TRANSFER",
    label: "حوالة بنكية",
  },
  {
    value: "EWALLET",
    label: "محفظةالكترونية",
  },
  {
    value: "CREDIT_CARD",
    label: "بطاقة ائتمان",
  },
];

const PaymentInfo = ({ showError, onChange }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Stack spacing={3} style={{ marginTop: 30 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">الخدمة والدفع</Typography>
        <Typography variant="title" sx={{ color: "red", fontWeight: "bold", fontSize: 14 }}>
          سعر التوصيل: 123
        </Typography>
      </Stack>

      <Grid container spacing={2} style={{ marginTop: 5 }}>
        <Grid item xs={6}>
          <Controller
            name="paymentMethod"
            control={control}
            render={({ field }) => (
              <TextField {...field} select fullWidth size="small" label="طريقة الدفع" defaultValue="COD">
                {PAYMENT_TYPE_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="totalCollection"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.totalCollection}
                size="small"
                label="التحصيل شامل التوصيل"
                variant="outlined"
                fullWidth
                placeholder="0.00"
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="collectionMethod"
            control={control}
            render={({ field }) => (
              <TextField {...field} select fullWidth size="small" label="طريقة التحصيل" defaultValue="CASH">
                {PAYMENT_MEtHOD_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="referenceNumber"
            control={control}
            render={({ field }) => (
              <TextField {...field} size="small" label="الرقم المرجعي" variant="outlined" fullWidth />
            )}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default PaymentInfo;
