import { useMemo } from "react";
import UtilityService from "service/utility";
import { fCurrency } from "utils/format-number";
import { fPackageId } from "utils/format-number";

const CollectionItemsColumns = () => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "packageId",
        header: " رقم الطرد",
        Cell: ({ cell }) => fPackageId(cell.getValue()),
      },
      {
        accessorKey: "packageStatus",
        header: "الحالة",
        Cell: ({ cell }) => UtilityService.renderStatusMessage("PACKAGE", cell.getValue()),
      },
      {
        accessorKey: "csutomerName",
        header: "اسم الزبون",
      },
      {
        accessorKey: "csutomerMobile",
        header: "رقم المستقبل",
      },
      {
        accessorKey: "address",
        header: "العنوان",
      },
      {
        accessorFn: (originalRow) => new Date(originalRow.pacakgeDate), //convert to date for sorting and filtering
        header: "التاريخ ",
        Cell: ({ cell }) => cell.getValue().toLocaleDateString(), // convert back to string for display
      },
      {
        accessorKey: "totalCollections",
        header: "التحصيل",
        Cell: ({ cell }) => fCurrency(cell.getValue()),
      },
      {
        accessorKey: "cost",
        header: "التوصيل",
        Cell: ({ cell }) => fCurrency(cell.getValue()),
      },
      {
        header: "تحصيل التاجر",
        Cell: ({ cell }) => fCurrency(cell.row.original.totalCollections - cell.row.original.cost),
      },
    ],
    []
  );

  return columns;
};

export default CollectionItemsColumns;
