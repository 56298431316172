import { Stack, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useFormContext, Controller } from "react-hook-form";
import dayjs from "dayjs";

const PackageDetails = ({ showError }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Stack spacing={2}>
      <Typography variant="h5">معلومات الطرد</Typography>

      <Grid container spacing={2} style={{ marginTop: 5 }}>
        <Grid item xs={12}>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.description}
                size="small"
                label="محتويات الطرد (اختياري)"
                variant="outlined"
                fullWidth
                margin="normal"
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="quantity"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.quantity}
                size="small"
                label="عدد عناصر الطرد"
                variant="outlined"
                fullWidth
                margin="normal"
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="weight"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.weight}
                size="small"
                label="الوزن الاجمالي لطرد"
                variant="outlined"
                fullWidth
                margin="normal"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="deliveryDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                defaultValue={dayjs()}
                minDate={dayjs()}
                label="تاريخ التحميل المتوقع"
                slotProps={{ textField: { size: "small", fullWidth: true } }}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={{ marginTop: 10 }}>
        <Grid item xs={6}>
          <Controller
            name="brackable"
            control={control}
            render={({ field }) => (
              <FormControlLabel style={{ margin: 0 }} control={<Checkbox {...field} />} label="الطرد قابل للكسر" />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="allowOpen"
            control={control}
            render={({ field }) => (
              <FormControlLabel style={{ margin: 0 }} control={<Checkbox {...field} />} label="غير قابل للفتح" />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="needCooling"
            control={control}
            render={({ field }) => (
              <FormControlLabel style={{ margin: 0 }} control={<Checkbox {...field} />} label="يحتاج الى تبريد" />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="allowOpen"
            control={control}
            render={({ field }) => (
              <FormControlLabel style={{ margin: 0 }} control={<Checkbox {...field} />} label="ممنوع القياس" />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="dangerous"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                style={{ margin: 0 }}
                control={<Checkbox {...field} />}
                label="يحتوي مواد مشتعلة أو خطرة"
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="needPackaging"
            control={control}
            render={({ field }) => (
              <FormControlLabel style={{ margin: 0 }} control={<Checkbox {...field} />} label="بحاجة للتغليف" />
            )}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default PackageDetails;
