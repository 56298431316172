import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

//Date Picker Imports - these should just be in your Context Provider
import { Delete } from "@mui/icons-material";
import { ListItemIcon, MenuItem, ThemeProvider, createTheme, useTheme } from "@mui/material";
import { arSA } from "@mui/material/locale";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DeletePackageFromInvoiceModel from "components/Modals/DeletePackageFromInvoiceModel";
import { MRT_Localization_AR } from "material-react-table/locales/ar/index.js";
import { useRef, useState } from "react";
import CollectionItemsColumns from "./CollectionItemsColumns";

const Table = (props) => {
  const { data, isPaid } = props;

  const [isChangeModalOpen, setChangeModalOpen] = useState(false);
  const editingRow = useRef(null);

  const handleDeletePackageFromInvoice = () => {
    //logic for delete
    setChangeModalOpen(false);
  };

  const table = useMaterialReactTable({
    columns: CollectionItemsColumns(),
    data,
    enableColumnResizing: true,
    columnResizeDirection: "rtl",
    enableRowSelection: false,
    enableRowActions: !isPaid,
    initialState: {
      showColumnFilters: false,
      columnPinning: { right: ["mrt-row-actions"] },
    },
    localization: { ...MRT_Localization_AR },
    enableFullScreenToggle: true,
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiTableBodyCellProps: { align: "right" },
    enablePagination: false,
    positionToolbarAlertBanner: "head-overlay",
    enableTopToolbar: false,
    enableColumnFilters: false,
    enableSorting: false,
    ...(!isPaid && {
      renderRowActionMenuItems: ({ closeMenu, row }) => [
        <MenuItem
          key={0}
          onClick={() => {
            setChangeModalOpen(true);
            editingRow.current = { row };
            closeMenu();
          }}
          sx={{ m: 0 }}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          حذف
        </MenuItem>,
      ],
    }),
  });

  return (
    <div>
      <h3 className="mb-3 mr-3"> الطرود</h3>
      <MaterialReactTable table={table} />
      {editingRow.current && (
        <DeletePackageFromInvoiceModel
          isOpen={isChangeModalOpen}
          onClose={() => setChangeModalOpen(false)}
          onConfirmDeletion={handleDeletePackageFromInvoice}
          packageId={editingRow.current.row.original.id}
        />
      )}
    </div>
  );
};

const CollectionItems = (props) => {
  const theme = useTheme(); //replace with your theme/createTheme

  return (
    //Setting Material UI locale as best practice to result in better accessibility
    <ThemeProvider theme={createTheme(theme, arSA)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Table data={props.data} isPaid={props.isPaid} />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default CollectionItems;
