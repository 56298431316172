import { Card, CardContent, CardHeader, Stack } from "@mui/material";
import { fCurrency } from "utils/format-number";
import RowItem from "./RowItem";

const Summary = ({ totalCollections, totalCost, amount }) => {
  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        title="الملخص"
        titleTypographyProps={{ variant: "h6" }}
        style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)", fontWeight: "bold" }}
      />

      <CardContent>
        <Stack>
          <RowItem title="مجموع التحصيل:" value={fCurrency(totalCollections)} />
          <RowItem title="تكلفة التوصيل:" value={fCurrency(totalCost)} />
          <RowItem title="مطلوب لتاجر:" value={fCurrency(amount)} />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Summary;
