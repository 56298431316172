import { Tabs } from "antd";
import moment from "moment";
import { useState } from "react";
import { Button, Modal } from "reactstrap";
import CollectionService from "service/collection";
import BankTransfer from "./BankTransfer";
import Cash from "./Cash";
import Wallet from "./Wallet";

const PAYMENT_TYPES = [
  { key: "CASH", title: "تسليم نقدي" },
  { key: "BANK", title: "حوالة بنكية" },
  { key: "WALLET", title: "محفظة الكترونية" },
];

function PaymentDialog({ collectionsId, open, onClose, onChange }) {
  const defaultValues = {
    paymentDate: moment().format("YYYY-MM-DD"),
    paymentMethod: "",
    paymentType: "CASH",
    referenceNumber: "",
  };

  const [data, setData] = useState(defaultValues);

  const [error, setError] = useState("");

  const closeModal = () => {
    onClose();
    setData(defaultValues);
    setError("");
  };

  const onDataChange = (inputData) => {
    setData({
      ...data,
      ...inputData,
    });
  };

  const handleTabChange = (tab) => {
    setError("");
    setData({
      ...data,
      paymentType: tab,
    });
  };

  const handleSumbit = () => {
    if (validateForm()) {
      CollectionService.payCollections(collectionsId, data)
        .then((res) => {
          if (res.data.success) {
            setError("");
            onChange();
            closeModal();
          } else {
            setError("ناسف! حدث خطا اثناء معاجة الطلب يرجى المحاولة مرة اخرى");
          }
        })
        .catch((error) => {
          setError("ناسف! حدث خطا اثناء معاجة الطلب يرجى المحاولة مرة اخرى");
        });
    } else {
      setError("الرجاء ملء الحقول المطلوبة");
    }
  };

  const validateForm = () => {
    if (!data.paymentDate) {
      return false;
    }
    if (data.paymentType === "BANK" || data.paymentType === "WALLET") {
      if (!data.paymentMethod || !data.referenceNumber) {
        return false;
      }
    }
    return true;
  };

  const renderTabContent = (key) => {
    if (key === "CASH") {
      return <Cash onChange={onDataChange} />;
    } else if (key === "BANK") {
      return <BankTransfer onChange={onDataChange} />;
    } else if (key === "WALLET") {
      return <Wallet onChange={onDataChange} />;
    }
    return null;
  };

  const tabsItems = PAYMENT_TYPES.map((type) => {
    return {
      key: type.key,
      label: type.title,
      children: renderTabContent(type.key),
    };
  });

  return (
    <Modal className="modal-dialog-centered" isOpen={open} toggle={closeModal}>
      <div className="modal-body">
        <Tabs defaultActiveKey={1} onChange={handleTabChange} items={tabsItems}></Tabs>
        <p className="text-danger">{error}</p>
      </div>

      <div className="modal-footer">
        <Button color="secondary" data-dismiss="modal" type="button" onClick={closeModal}>
          اغلاق
        </Button>
        <Button color="primary" type="button" onClick={handleSumbit}>
          حفظ
        </Button>
      </div>
    </Modal>
  );
}
export default PaymentDialog;
