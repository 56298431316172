import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/plugins/nucleo/css/nucleo.css";
import "assets/scss/argon-dashboard-react.scss";
import ReactDOM from "react-dom/client";
import { usePromiseTracker } from "react-promise-tracker";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "./layouts/Auth.js";
import FullPageLayout from "./layouts/FullPage.js";
import PrivateRoute from "./privetRoute";
import { RingSpinnerOverlay } from "react-spinner-overlay";
import { ThemeProvider, createTheme } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return promiseInProgress && <RingSpinnerOverlay loading={true} size={100} color="#FE6F2F" borderWidth="4" />;
};

// Create a custom theme with your primary color
const theme = createTheme({
  direction: "rtl",
  palette: {
    primary: {
      main: "#FF5733",
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          textAlign: "right", // Aligns the label text to the right
          right: 30, // Ensures the label stays within the input area
          transformOrigin: "top right", // Adjusts the transform origin for animations
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& legend": {
            textAlign: "right", // Align legend to the right
          },
        },
        notchedOutline: {
          textAlign: "right", // Align the notch for the legend
        },
      },
      input: {
        textAlign: "right",
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          left: 0, // Move the arrow to the left
          right: "unset", // Unset the default right alignment
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popupIndicator: {
          position: "absolute",
          left: 0, // Move the dropdown arrow to the left
          right: "unset", // Unset default right alignment
        },
        endAdornment: {
          position: "absolute",
          left: 0,
          right: "unset", // Optional: Adjust overall adornment alignment if needed
        },
      },
    },
  },
});

root.render(
  <>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter>
          <Routes>
            <Route path="/auth/*" element={<AuthLayout />} />

            <Route path="/not-found/*" exact element={<FullPageLayout />} />

            <Route path="*" element={<PrivateRoute />}>
              <Route path="*" element={<AdminLayout />} />
            </Route>
          </Routes>
        </BrowserRouter>
        <LoadingIndicator />
      </LocalizationProvider>
    </ThemeProvider>
  </>
);
