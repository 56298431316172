import { useMemo } from "react";
import { Link } from "react-router-dom";
import UtilityService from "service/utility";
import CollectionTableRowActions from "./CollectionTableRowActions";

const CollectionTableColumns = () => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        id: "collectionId",
        header: "#",
        size: 250,
        Cell: ({ cell }) => (
          <Link className="text-dark text-underline" to={"/collections/" + cell.getValue()}>
            {cell.getValue()}
          </Link>
        ),
      },
      {
        accessorFn: (originalRow) => (originalRow.created ? new Date(originalRow.created) : ""),
        header: "تاريخ الإنشاء",
        size: 250,
        Cell: ({ cell }) => (cell.getValue() ? cell.getValue().toLocaleDateString() : ""),
      },
      {
        accessorKey: "totalCollections",
        header: "مجموع التحصيل",
        size: 250,
        Cell: ({ cell }) =>
          cell.getValue()?.toLocaleString("en-US", {
            style: "currency",
            currency: "ILS",
          }),
      },
      {
        accessorKey: "totalCost",
        header: "تكلفة التوصيل",
        size: 250,
        Cell: ({ cell }) =>
          cell.getValue()?.toLocaleString("en-US", {
            style: "currency",
            currency: "ILS",
          }),
      },
      {
        accessorKey: "amount",
        header: "تحصيل التاجر",
        size: 250,
        Cell: ({ cell }) =>
          cell.getValue()?.toLocaleString("en-US", {
            style: "currency",
            currency: "ILS",
          }),
      },
      {
        accessorKey: "status",
        header: "الحالة",
        Cell: ({ cell }) => UtilityService.renderStatusMessage("COLLECTION", cell.getValue()),
      },
      {
        accessorKey: "id",
        id: "actions",
        header: "إجراءات",
        Cell: ({ cell }) => <CollectionTableRowActions collectionId={cell.getValue()} />,
      },
    ],
    []
  );
  return columns;
};

export default CollectionTableColumns;
