/**
 * Format Number
 * @param {*} inputValue
 * @param {*} minimumFractionDigits
 * @param {*} maximumFractionDigits
 * @returns
 */
export function fNumber(inputValue, minimumFractionDigits = 0, maximumFractionDigits = 2) {
  if (!inputValue && inputValue !== 0) return "";

  const number = Number(inputValue);

  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(number);
}

/**
 *Format number as currency
 * @param {*} inputValue
 * @param {*} minimumFractionDigits
 * @param {*} maximumFractionDigits
 * @returns
 */
export function fCurrency(inputValue, minimumFractionDigits = 0, maximumFractionDigits = 2) {
  if (!inputValue && inputValue !== 0) return "";

  const number = Number(inputValue);

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "ILS",
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(number);
}

export function fPercent(inputValue) {
  if (!inputValue && inputValue != 0) return "";

  const number = Number(inputValue) / 100;

  return new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  }).format(number);
}

/**
 * Format short number
 * @param {*} inputValue
 * @returns
 */
export function fShortenNumber(inputValue) {
  if (!inputValue && inputValue !== 0) return "";

  const number = Number(inputValue);

  return new Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 2,
  })
    .format(number)
    .replace(/[A-Z]/g, (match) => match.toLowerCase());
}

/**
 * Format package id with pattern PKG-XXX-XXX-XXX
 * @param {*} id
 * @returns
 */
export function fPackageId(id) {
  if (!id && id !== 0) return "";

  // Convert the number to a string and pad with leading zeros if necessary
  let numberStr = id.toString().padStart(9, "0");

  // Split the string into groups of three and format with "PKG-"
  return `PKG-${numberStr.slice(0, 3)}-${numberStr.slice(3, 6)}-${numberStr.slice(6)}`;
}
