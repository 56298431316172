import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { Autocomplete, TextField, Button } from "@mui/material";
import AddressService from "service/address";
import UtilityService from "service/utility";
import CustomerService from "service/customer";

const CustomerSearch = () => {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const values = watch();

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [customerOptions, setCustomerOptions] = useState(null);
  const [search, setSearch] = useState("");

  // Handle input change to fetch API results
  const handleInputChange = async (event, value) => {
    if (!value) {
      setOptions([]);
    }

    setSearch(value);

    setLoading(true);
    if (value && value != values.mobile1) {
      try {
        // Replace this with your actual API
        const response = await CustomerService.findCustomer(value, 5, 0);

        // Assuming API returns an array of objects with a `label` property
        setCustomerOptions(response.data.customers);
        setOptions(
          response.data.customers.map((customer) => ({
            label: `${customer.firstName} ${customer.lastName} - ${customer.mobile}`,
            id: customer.customerId,
            name: `${customer.firstName} ${customer.lastName}`,
            mobile: customer.mobile,
          })) || []
        );
      } catch (error) {
        console.error("Error fetching options:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCustomerSelect = (event, value) => {
    if (value) {
      setValue("customerName", value.name);
      setValue("mobile1", value.mobile);
    } else {
      setValue("customerName", null);
      setValue("mobile1", null);
    }
    setOptions([]);
  };

  const renderValue = () => {
    if (values && values.mobile1) {
      return values.mobile1;
    }
    return search;
  };

  return (
    <Autocomplete
      inputValue={renderValue()}
      options={options}
      loading={loading}
      getOptionLabel={(option) => option.label || ""}
      onInputChange={(_, value) => {
        handleInputChange(_, value); // Fetch API data
      }}
      onChange={handleCustomerSelect}
      renderOption={(props, option) => (
        <li
          {...props}
          style={{
            "&:hover": {
              backgroundColor: "lightgray",
            },
            padding: "8px", // Add padding to each option
            fontWeight: "bold", // Example: bold text
            fontSize: 12,
          }}>
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!errors.areaCode}
          label="رقم موبايل"
          variant="outlined"
          size="small"
          fullWidth
          margin="normal"
          InputProps={{
            ...params.InputProps,
            endAdornment: params.InputProps.endAdornment,
          }}
        />
      )}
    />
  );
};

export default CustomerSearch;
