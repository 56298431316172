import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import OrganizationService from "service/organization";

const OrganizationSelect = (props) => {
  const [organizationId, setOrganizationId] = useState(props.defaultValue || "");
  const [organizationList, setOrganizationList] = useState([]);

  const handleChange = (e) => {
    setOrganizationId(e.target.value);
    if (props.onChange) {
      props.onChange(organizationList.find((o) => o.id == e.target.value));
    }
  };

  useEffect(() => {
    OrganizationService.getAllOrganizationList(true, false).then((res) => {
      setOrganizationList(res.data);
    });
  }, []);

  return (
    <TextField
      select
      fullWidth
      size="small"
      label="الشركة \ التاجر"
      defaultValue=""
      value={organizationId}
      onChange={handleChange}>
      <MenuItem value=""></MenuItem>

      {organizationList &&
        organizationList.map((org) => (
          <MenuItem value={org.id} key={org.id}>
            {org.id} - {org.name}
          </MenuItem>
        ))}
    </TextField>
  );
};

export default OrganizationSelect;
