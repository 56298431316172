import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import OrganizationSelect from "components/Organization/OrganizationSelect";
import { useFormContext } from "react-hook-form";
import UtilityService from "service/utility";

const OrganizationInfo = ({ organization, onOrganizationChange, onBranchChange }) => {
  const {
    formState: { errors },
  } = useFormContext();

  const activeBranchList = organization?.branchList.filter((b) => b.status);

  const handleBranchChange = (e) => {
    const branch = activeBranchList.find((o) => o.id == e.target.value);
    onBranchChange(branch);
  };

  return (
    <Stack spacing={3}>
      <Typography variant="h5">معلومات التاجر</Typography>

      {UtilityService.isAdmin() && (
        <Grid container spacing={2} style={{ marginTop: 5 }}>
          <Grid item xs={6}>
            <OrganizationSelect onChange={onOrganizationChange} />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled={!activeBranchList || activeBranchList.length === 0}
              select
              fullWidth
              size="small"
              label="الفرع"
              defaultValue=""
              onChange={handleBranchChange}
              error={!!errors.branchId}>
              <MenuItem value=""></MenuItem>
              {activeBranchList &&
                activeBranchList.map((b) => (
                  <MenuItem value={b.id} key={b.id}>
                    {b.id} - {b.name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
        </Grid>
      )}
    </Stack>
  );
};

export default OrganizationInfo;
