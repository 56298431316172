import AreaAPI from "api/area";

const AddressService = {
  getAreaByCity: function (city) {
    return AreaAPI.getAreaByCity(city);
  },
  getAllArea: function () {
    return AreaAPI.getAllArea();
  },
  searchArea: function (search, pageSize, pageNumber) {
    return AreaAPI.searchArea(search, pageSize, pageNumber);
  },
};

export default AddressService;
