import { CURRENCY_SYMBOL } from "variables/constant";
import { MESSAGES } from "variables/messages";
import UserService from "./user";
import { packageTransition } from "variables/packageTransition";
import { SUPPORTED_CITY } from "variables/cities";
import moment from "moment";

const UtilityService = {
  getMonthName: function (month) {
    const currentYear = new Date().getFullYear();
    const date = new Date(currentYear, month - 1);
    return date.toLocaleString("en-US", { month: "short" });
  },
  debounce: function (fn, delay) {
    let timer = null;
    return function (...args) {
      const context = this;
      timer && clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, delay);
    };
  },
  formatAddress: function (address, fullAddress = true) {
    let res = "";
    if (address) {
      if (address.area) {
        res = UtilityService.renderCity(address.area?.city) + ", " + address.area?.arabicName;
      }
      if (fullAddress && (address.address1 || address.address2)) {
        if (address.area) {
          res += ", ";
        }
        res += (address.address1 || "") + " " + (address.address2 || "");
      }
      return res;
    }
    return res;
  },
  renderCity: function (cityName) {
    if (!cityName) return "";
    const city = SUPPORTED_CITY.find((c) => c.key === cityName?.toLowerCase());
    return city ? city.title : cityName;
  },
  renderState: function (stateName) {
    if (!stateName) return "";
    if (stateName == "WEST_BANK") return "الضفة الغربية";
    if (stateName == "48AREA") return "الداخل";
    if (stateName == "JERUSALEM") return "القدس";
    return stateName;
  },
  renderStatusMessage: function (entity, status, useBadge = true, textOnly = false) {
    let key = "";
    switch (entity) {
      case "PACKAGE":
        key = "PACKAGE_STATUS_" + status;
        break;
      case "COLLECTION":
        key = "COLLECTION_STATUS_" + status;
        break;
      default:
        break;
    }
    const message = MESSAGES[key];
    if (message && !textOnly) {
      return (
        <span title={message.hint || ""} className={message.badge && useBadge ? "badge badge-" + message.badge : ""}>
          {message.text}
        </span>
      );
    } else if (message) {
      return message.text;
    }
    return status;
  },
  renderActiveOrInactiveStatus: function (status) {
    if (status) {
      return <span className="badge badge-success">نشط</span>;
    } else {
      return <span className="badge badge-danger">غير نشط</span>;
    }
  },
  formatPrice: function (price) {
    const p = parseFloat(price);
    return (
      (isNaN(p)
        ? "0.00"
        : p
            .toLocaleString("en-US", {
              style: "currency",
              currency: "ILS",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
            .replace("₪", "")) +
      " " +
      CURRENCY_SYMBOL
    );
  },
  isValidAddress: function (address) {
    if (!address) {
      return false;
    }
    if (!address.area) {
      return false;
    }
    if (!address.address1) {
      return false;
    }
    return true;
  },
  isAdmin: function () {
    const user = UserService.getCurrentUser();
    return user && user.role && user.role === "ADMIN";
  },
  isOrgUser: function () {
    const user = UserService.getCurrentUser();
    return user && user.role && user.role === "ORG_USER";
  },
  isOrgAdmin: function () {
    const user = UserService.getCurrentUser();
    return user && user.role && user.role === "ORG_USER" && user.orgAdmin;
  },
  getCurrentOrganizationId: function () {
    const user = UserService.getCurrentUser();
    return user?.organizationId;
  },
  formatTimestamp: function (timestamp) {
    return new Date(timestamp).toLocaleDateString("ar-EG", {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  },

  formatDate: function (timestamp) {
    return moment(timestamp).format("DD/MM/YYYY");
  },
  formatTime: function (timestamp) {
    return moment(timestamp).format("hh:mm A");
  },

  isAllowToMovePackage: function (oldStatus, newStatus) {
    let isAllow = false;
    if (packageTransition[oldStatus] && packageTransition[oldStatus].indexOf(newStatus) != -1) {
      isAllow = true;
    }
    return isAllow;
  },
  formatBytes: function (bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  },
};

export default UtilityService;
