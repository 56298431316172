import { useEffect, useState } from "react";
import PackageService from "service/package";
import UtilityService from "service/utility";
import { PACKAGE_STATUS_LIST } from "variables/constant";
import Grid from "@mui/material/Grid";
import PackageStatisticChartWidget from "./components/PackageChartWidget";
import PackageStatisticWidget from "./components/PackageStatisticWidget";
import { Box } from "@mui/material";

const colors = [
  "#11cdef",
  "#9370DB",
  "#DAA520",
  "#8B008B",
  "#24b47e",
  "#d782d9",
  "#8B0000",
  "#f5365c",
  "#000000",
  "#FF69B4",
  "#5F9EA0",
  "#E9967A",
  "#A9A9A9",
  "#FFA500",
  "#31572C",
  "#c1121f",
  "#0000FF",
  "#11960C",
];

const PackageStatistic = () => {
  const [summaryData, setSummaryData] = useState([]);

  useEffect(() => {
    PackageService.getPackageSummary().then((res) => {
      setSummaryData(res.data);
    });
  }, []);

  const totalPackage = Object.values(summaryData).reduce(
    (accumulator, currentValue) => accumulator + currentValue.count,
    0
  );

  return (
    <Grid
      container
      spacing={3}
      sx={{
        display: "grid",
        gridTemplateColumns: {
          lg: "repeat(6, 1fr)",
          md: "repeat(4, 1fr)",
          sm: "repeat(2, 1fr)",
        },
        gap: 2,
        width: { xs: "100%" },
      }}>
      <Box
        sx={{
          gridColumn: "span 2",
          gridColumnStart: 1,
          gridColumnEnd: 3,
          gridRow: {
            md: "span 3",
          },
          gridRowStart: { md: 1 },
          gridRowEnd: { md: 4 },
        }}>
        <PackageStatisticChartWidget data={summaryData} totalPackage={totalPackage} colors={colors} />
      </Box>

      {PACKAGE_STATUS_LIST.map((status, index) => {
        const obj = summaryData.find((item) => item.status === status);
        return (
          <Box key={index} sx={{ height: 120 }}>
            <PackageStatisticWidget
              totalPackage={totalPackage}
              title={UtilityService.renderStatusMessage("PACKAGE", status, false, true)}
              value={obj?.count || 0}
              color={colors[index]}
              status={status}
            />
          </Box>
        );
      })}
    </Grid>
  );
};

export default PackageStatistic;
