import { useRef, useState } from "react";

import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

//Date Picker Imports - these should just be in your Context Provider
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

//Material UI Imports
import { ListItemIcon, MenuItem } from "@mui/material";

//Icons Imports
import { CalendarToday, Check, Link, Person, Print } from "@mui/icons-material";

import { MRT_Localization_AR } from "material-react-table/locales/ar/index.js";

import { ThemeProvider, createTheme, useTheme } from "@mui/material";
import { arSA } from "@mui/material/locale";
import PackageService from "service/package";
import ChangeDeliveryDateModel from "./ChangeDeliveryDateModel";
import LinkToExternalSystemModal from "./LinkToExternalSystemModal";
import PackageTableColumn from "./PackageTableColumn";
import PackageTableTopBar from "./PackageTableTopBar";
import UpdateCollectionCost from "./UpdateCollectionCost";
import UpdateDeliveryCost from "./UpdateDeliveryCost";

const Table = (props) => {
  const { data } = props;
  const editingRow = useRef(null);
  const [isChangeModalOpen, setChangeModalOpen] = useState(false);
  const [isSetFilter, setFilter] = useState(false);
  const status = new URLSearchParams(window.location.search).get("status");

  const handlePrintBill = () => {
    const id = [editingRow.current.row.original.id];
    PackageService.printBill(id).then((res) => {
      if (res.data.success) {
        const newTab = window.open(process.env.REACT_APP_STATIC_REPORT_URL + res.data.fileName, "_blank");
        if (newTab) {
          newTab.focus();
        }
      }
    });
  };

  const handleDeliveryCostChange = (newValue) => {
    const id = [editingRow.current.row.original.id];

    PackageService.updatePackageCost(id, newValue).then((res) => {
      if (res.data.success) {
        props.onDataChange();
      }
    });
    setChangeModalOpen(false);
  };

  const handleTotalCollectionChange = (newValue) => {
    const id = [editingRow.current.row.original.id];
    PackageService.updatePackageTotalCollection(id, newValue).then((res) => {
      if (res.data.success) {
        props.onDataChange();
      }
    });
    setChangeModalOpen(false);
  };

  const handleDeliveryDateChange = (newDeliveryDate, isStatusChangeChecked) => {
    const id = [editingRow.current.row.original.id];
    const data = {
      deliveryDate: newDeliveryDate,
    };
    if (isStatusChangeChecked) data.status = "S";

    PackageService.updatePackageDeliveryDate(id, data)
      .then((res) => {
        if (res.data.success) {
          props.onDataChange();
        }
      })
      .catch((error) => {
        console.error("Request failed with error:", error);
      });

    setChangeModalOpen(false);
  };

  const handelLinkToExternalSystem = () => {
    props.onDataChange();
  };

  const table = useMaterialReactTable({
    columns: PackageTableColumn(),
    data,
    enableColumnResizing: false,
    columnRssesizeDirection: "rtl",
    enableRowSelection: true,
    enableRowActions: false,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "id"],
        right: ["mrt-row-actions"],
      },
    },
    localization: { ...MRT_Localization_AR },
    enableFullScreenToggle: true,
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    columnFilterDisplayMode: "popover",
    enablePagination: true,
    positionToolbarAlertBanner: "head-overlay",
    enableColumnPinning: true,
    enableColumnActions: false,
    muiTableBodyCellProps: {
      sx: {
        align: "right",
        right: 0,
      },
    },
    muiTableHeadCellProps: {
      sx: {
        right: 0,
        align: "right",
      },
    },
    onDataChange: props.onDataChange,
    renderTopToolbar: PackageTableTopBar,
  });

  if (!isSetFilter && status) {
    table
      .getAllColumns()
      .find((column) => column.id === "status")
      .setFilterValue([status]);
    setFilter(true);
  }

  return (
    <div style={{ overflowX: "auto" }}>
      <MaterialReactTable table={table} />
    </div>
  );
};

const PackageTable = (props) => {
  const theme = useTheme(); //replace with your theme/createTheme

  // define direction for table to solve pagination Icons is in wrong direction
  const direction = { direction: "rtl" };

  return (
    //Setting Material UI locale as best practice to result in better accessibility
    <ThemeProvider theme={createTheme(theme, arSA, direction)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Table data={props.data} onDataChange={props.onDataChange} />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default PackageTable;
