import DriverSelect from "components/Organization/DriverSelect";
import React from "react";
import { Button, FormGroup, Modal } from "reactstrap";
import PackageService from "service/package";

class AssignDriverModal extends React.Component {
  state = {
    isOpen: false,
    driverId: null,
    errorMessage: "",
  };

  openModal = () => {
    this.setState({
      isOpen: true,
      driverId: this.props.driverId,
      errorMessage: "",
    });
  };

  onDriverChange = (driver) => {
    this.setState({
      driverId: driver.userId,
    });
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  handleSubmit = () => {
    this.setState({ errorMessage: "" });
    const self = this;
    if (this.state.driverId) {
      PackageService.assigneDriverToPackage(this.props.packageId, this.state.driverId)
        .then((res) => {
          if (self.props.onDriverChanged) {
            self.props.onDriverChanged();
          }
          self.closeModal();
        })
        .catch(() => {
          this.setState({ errorMessage: "ناسف, فشل في تعين السائق. الرجاء المحاولة مرة اخرى لاحقا." });
        });
    } else {
      this.setState({ errorMessage: "الرجاء اختيار السائق" });
    }
  };

  render() {
    const { isOpen, driverId } = this.state;
    return (
      <>
        <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={() => this.closeModal()}>
          <div className="modal-body pb-0">
            <div justify-content="center">
              <div>
                <div>
                  <FormGroup className="mb-2">
                    <label className="form-control-label">الرجاء اختيار السائق:</label>
                    <DriverSelect driverId={driverId} onChange={this.onDriverChange} />
                  </FormGroup>
                </div>
                <p className="text-danger mb-0 mt-2">&nbsp;{this.state.errorMessage}</p>
              </div>
            </div>
          </div>

          <div className="modal-footer pt-0">
            <Button color="primary" type="button" className="w-100" onClick={this.handleSubmit}>
              حفظ
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default AssignDriverModal;
