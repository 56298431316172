import { IconButton, Stack } from "@mui/material";
import { Download, Print } from "@mui/icons-material";
import CollectionService from "service/collection";

const CollectionTableRowActions = ({ collectionId }) => {
  const handlePrintCollectionInvoice = () => {
    CollectionService.printCollectionInvoice(collectionId).then((res) => {
      if (res.data.success) {
        const newTab = window.open(process.env.REACT_APP_STATIC_REPORT_URL + res.data.fileName, "_blank");
        if (newTab) {
          newTab.focus();
        }
      }
    });
  };

  const handleDownloadPDFFile = () => {
    CollectionService.printCollectionInvoice(collectionId).then((res) => {
      if (res.data.success) {
        CollectionService.downloadCollectionInvoicePdf(res.data.fileName)
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.download = res.data.fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            console.error("Error fetching the file:", error);
          });
      }
    });
  };

  return (
    <Stack direction="row" spacing={1}>
      <IconButton variant="contained" color="primary" onClick={handlePrintCollectionInvoice}>
        <Print />
      </IconButton>

      <IconButton variant="contained" color="primary" onClick={handleDownloadPDFFile}>
        <Download />
      </IconButton>
    </Stack>
  );
};

export default CollectionTableRowActions;
