import { CalendarToday, Check, Link, Person, Print } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, ListItemText, MenuItem, Stack } from "@mui/material";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import { useBoolean } from "hooks/useBoolean";
import { useState } from "react";
import AssignDriverModal from "./AssignDriverModal";
import ChangeDeliveryDateModel from "./ChangeDeliveryDateModel";
import LinkToExternalSystemModal from "./LinkToExternalSystemModal";
import UpdateCollectionCost from "./UpdateCollectionCost";
import UpdateDeliveryCost from "./UpdateDeliveryCost";

const PackageActions = ({ packageDetails, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const linkToExternalSystem = useBoolean(false);
  const changeDeliveryDate = useBoolean(false);
  const changeDeliveryCost = useBoolean(false);
  const changeCollectionCost = useBoolean(false);
  const assignToDriver = useBoolean(false);

  return (
    <>
      <Stack>
        {/* 3-dot button */}
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(event) => setAnchorEl(event.currentTarget)}>
          <MoreVertIcon />
        </IconButton>

        <Menu
          open={open}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorEl={anchorEl}>
          <MenuItem onClick={changeDeliveryCost.onTrue}>
            <ListItemIcon>
              <Check fontSize="small" />
            </ListItemIcon>
            <ListItemText>تعديل قيمة التوصيل</ListItemText>
          </MenuItem>
          <MenuItem onClick={changeCollectionCost.onTrue}>
            <ListItemIcon>
              <Check fontSize="small" />
            </ListItemIcon>
            <ListItemText>تعديل قيمة التحصيل</ListItemText>
          </MenuItem>
          <MenuItem onClick={assignToDriver.onTrue}>
            <ListItemIcon>
              <Person fontSize="small" />
            </ListItemIcon>
            <ListItemText>تعين سائق</ListItemText>
          </MenuItem>

          <MenuItem>
            <ListItemIcon>
              <Print fontSize="small" />
            </ListItemIcon>
            <ListItemText>طباعة بوليصة</ListItemText>
          </MenuItem>

          <MenuItem>
            <ListItemIcon>
              <CalendarToday fontSize="small" />
            </ListItemIcon>
            <ListItemText>تغير تاريخ التوصيل</ListItemText>
          </MenuItem>

          <Divider />

          <MenuItem onClick={linkToExternalSystem.onTrue}>
            <ListItemIcon>
              <Link fontSize="small" />
            </ListItemIcon>
            <ListItemText>ربط مع نظام خارجي</ListItemText>
          </MenuItem>
        </Menu>
      </Stack>

      {linkToExternalSystem.value && (
        <LinkToExternalSystemModal
          open={linkToExternalSystem.value}
          onClose={linkToExternalSystem.onFalse}
          packageId={packageDetails.id}
        />
      )}

      {changeDeliveryDate.value && (
        <ChangeDeliveryDateModel
          isOpen={changeDeliveryDate.value}
          onClose={changeDeliveryDate.onFalse}
          packageId={packageDetails.id}
        />
      )}

      {changeDeliveryCost.value && (
        <UpdateDeliveryCost
          isOpen={changeDeliveryCost.value}
          onClose={changeDeliveryCost.onFalse}
          packageId={packageDetails.id}
        />
      )}

      {changeCollectionCost.value && (
        <UpdateCollectionCost
          isOpen={changeCollectionCost.value}
          onClose={changeCollectionCost.onFalse}
          packageId={packageDetails.id}
        />
      )}
      {assignToDriver.value && (
        <AssignDriverModal
          open={assignToDriver.value}
          packageId={packageDetails.id}
          driverId={packageDetails.driver?.userId}
          onDriverChanged={() => {}}
        />
      )}
    </>
  );
};

export default PackageActions;
