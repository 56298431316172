import Login from "views/auth/Login.js";
import CustomersList from "views/customers/CustomersList";
import Index from "views/dashboard/Index.js";
import DriversManagment from "views/drivers/DriversManagment";
import CarsManagment from "views/cars/CarsManagment";
import OrganizationDetails from "views/organization/details/OrganizationDetails";
import BranchManagment from "views/organization/BranchManagment";
import OrganizationManagment from "views/organization/OrganizationManagment";
import UserManagment from "views/organization/UserManagment";
import ActivePackages from "views/packages/ActivePackages.js";
import PackageDetails from "views/packages/PackageDetails";
import PackagesHistory from "views/packages/PackagesHistory";
import Profile from "views/user/Profile.js";
import Settings from "views/settings/Settings.js";
import PriceListManagment from "views/price/PriceListManagment";
import AreaManagment from "views/area/AreaManagment";
import ChatList from "views/chat/index";
import Config from "views/config";
import Logs from "views/logs";
import CollectionList from "views/collection/CollectionList";
import CollectionDetails from "views/collection/details/CollectionDetails";

export const sidebarRoutes = [
  {
    path: "/",
    name: "الرئسية",
    icon: "ni ni-tv-2",
    iconColor: "#fe6f2f",
  },
  {
    path: "/packages",
    name: "الطرود النشطة",
    icon: "ni ni-app",
    iconColor: "#5e72e4",
  },
  {
    path: "/packages/history",
    name: "الطرود المغلقة",
    icon: "ni ni-app",
    iconColor: "#5e72e4",
  },
  {
    path: "/customers",
    name: "الزبائن",
    icon: "fas fa-users",
    iconColor: "#fb6340",
  },
  {
    path: "/collections",
    name: "التحصيلات",
    icon: "ni ni-planet",
    iconColor: "#5e72e4",
  },
  {
    path: "/profile",
    name: "الملف الشخصي",
    icon: "ni ni-single-02",
    iconColor: "#ffd600 ",
  },
  {
    path: "/settings",
    name: "الاعدادات",
    icon: "ni ni-settings-gear-65",
  },
  {
    path: "/users",
    name: "ادارة المستخدمين",
    icon: "ni ni-bullet-list-67",
    iconColor: "#2dce89",
    isAdmin: true,
  },
  {
    path: "/branch",
    name: "ادارة الفروع",
    icon: "ni ni-shop text-danger",
    isAdmin: true,
  },
];

export const adminSidebarRoutes = [
  {
    path: "/organization",
    name: "ادارة الشركات",
    icon: "ni ni-shop",
    iconColor: "#f5365c",
  },
  {
    path: "/users",
    name: "ادارة المستخدمين",
    icon: "ni ni-bullet-list-67",
    iconColor: "#2dce89",
  },
  {
    path: "/drivers",
    name: "ادارة السائقين",
    icon: "ni ni-single-02",
    iconColor: "#f5365c",
  },
  {
    path: "/cars",
    name: "ادارة السيارات",
    icon: "fas fa-truck",
    iconColor: "#5e72e4",
  },
  {
    path: "/area",
    name: "المناطق",
    icon: "fas fa-map-marked-alt",
    iconColor: "#5e72e4",
  },
  {
    path: "/price-list",
    name: "قائمة الاسعار",
    icon: "fas fa-dollar-sign",
    iconColor: "#5e72e4",
  },
  {
    path: "/config",
    name: "اعدادات النظام",
    icon: "ni ni-settings-gear-65",
    iconColor: "#000000",
  },
  {
    path: "/logs",
    name: "السجلات",
    icon: "fas fa-bug",
    iconColor: "#f5365c",
  },
];

export const authRoutes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
];

export const adminRoutes = [
  {
    path: "/",
    name: "الرئسية",
    component: Index,
  },
  {
    path: "/packages",
    name: "الطرود النشطة",
    component: ActivePackages,
  },
  {
    path: "/packages/history",
    name: "الطرود المغلقة",
    component: PackagesHistory,
  },
  {
    path: "/packages/:id",
    name: "تفاصيل الطرد",
    component: PackageDetails,
  },

  {
    path: "/customers",
    name: "الزبائن",
    component: CustomersList,
  },
  {
    path: "/collections",
    name: "التحصيلات",
    component: CollectionList,
  },
  {
    path: "/profile",
    name: "الملف الشخصي",
    component: Profile,
  },
  {
    path: "/users",
    name: "ادارة المستخدمين",
    component: UserManagment,
  },
  {
    path: "/branch",
    name: "ادارة الفروع",
    component: BranchManagment,
  },
  {
    path: "/organization",
    name: "ادارة الشركات",
    component: OrganizationManagment,
  },
  {
    path: "/organization/:id",
    name: "تفاصيل الشركة",
    component: OrganizationDetails,
  },
  {
    path: "/drivers",
    name: "ادارة السائقين",
    component: DriversManagment,
  },
  {
    path: "/cars",
    name: "ادارة السيارات",
    component: CarsManagment,
  },
  {
    path: "/settings",
    name: "الاعدادات",
    component: Settings,
  },
  {
    path: "/area",
    name: "المناطق",
    component: AreaManagment,
  },
  {
    path: "/price-list",
    name: "قائمة الاسعار",
    component: PriceListManagment,
  },
  {
    path: "/config",
    name: "اعدادات النظام",
    component: Config,
  },
  {
    path: "/logs",
    name: "السجلات",
    component: Logs,
  },
  {
    path: "/messages",
    component: ChatList,
  },
  {
    path: "/collections/:id",
    name: "تفاصيل الفاتورة",
    component: CollectionDetails,
  },
];
