import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const StatisticWidget = ({ url, title, value, icon, color }) => {
  return (
    <Card
      sx={{
        backgroundColor: color,
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        "&:hover": {
          transform: "scale(1.03)", // Scale up on hover
          boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.3)", // Stronger shadow on hover
        },
      }}>
      <CardContent>
        <Link to={url}>
          <Stack direction="row" style={{ gap: 10, alignItems: "center" }}>
            <Box
              className={"icon icon-shape bg-white rounded-circle shadow "}
              style={{ color: color, backgroundColor: "#ffffff" }}>
              <i className={icon} />
            </Box>

            <Stack spacing={1} style={{ marginLeft: 0 }}>
              <Typography variant="title" color="#FFFFFF" style={{ margin: 0, fontWeight: "bold" }}>
                {title}
              </Typography>
              <Typography variant="title" color="#FFFFFF" style={{ margin: 0, fontWeight: "bold" }}>
                {value}
              </Typography>
            </Stack>
          </Stack>
        </Link>
      </CardContent>
    </Card>
  );
};

export default StatisticWidget;
