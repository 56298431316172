import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useFormContext, Controller } from "react-hook-form";
import AreaSearch from "./AreaSearch";
import CustomerSearch from "./CustomerSearch";

const CustomerInfo = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Stack spacing={3} style={{ marginTop: 10 }}>
      <Typography variant="h5">معلومات الزبون</Typography>

      <Grid container spacing={1} style={{ marginTop: 5 }}>
        <Grid item xs={6}>
          <CustomerSearch />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="customerName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.customerName}
                size="small"
                label="اسم المستلم"
                variant="outlined"
                fullWidth
                margin="normal"
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <AreaSearch />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="mobile2"
            control={control}
            render={({ field }) => (
              <TextField {...field} size="small" label="رقم موبايل ثاني" variant="outlined" fullWidth margin="normal" />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                error={!!errors.address}
                label="العنوان بالتفصيل"
                variant="outlined"
                fullWidth
                margin="normal"
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="customerNote"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                label="ملاحظات الزبون"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                margin="normal"
              />
            )}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default CustomerInfo;
