import {
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
} from "material-react-table";
import { Box, Button, lighten } from "@mui/material";
import PackageService from "service/package";
import ChangePackageStatusModal from "./components/ChangePackageStatusModal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CreateCollectionInvoiceModel from "components/Modals/CreateCollectionInvoiceModel";
import { useBoolean } from "hooks/useBoolean";

const PackageTableTopBar = (props) => {
  const { table } = props;

  const selectedPackageIds = table.getSelectedRowModel().flatRows.map((row) => row.getValue("id"));

  //Check if some or all rows are selected and all selected rows have collectionStatus = "R"
  const isInvoiceCreationDisable =
    (table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) &&
    table
      .getSelectedRowModel()
      .flatRows.map((row) => row.original)
      .every((row) => row.collectionStatus === "R");

  const changeStatusDialog = useBoolean();

  const handleSelect = () => {
    alert("Selected Packages: " + selectedPackageIds.join(","));
  };

  const refreshTable = () => {
    table.options.onDataChange();
    table.setRowSelection([]);
  };

  const handlePrintBill = () => {
    const ids = table.getSelectedRowModel().flatRows.map((row) => row.getValue("id"));
    PackageService.printBill(ids).then((res) => {
      if (res.data.success) {
        const newTab = window.open(process.env.REACT_APP_STATIC_REPORT_URL + res.data.fileName, "_blank");
        if (newTab) {
          newTab.focus();
        }
      }
    });
  };

  const isDisableButton = !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected();
  return (
    <>
      <Box
        sx={(theme) => ({
          backgroundColor: lighten(theme.palette.background.default, 0.05),
          display: "flex",
          gap: "0.5rem",
          p: "8px",
          justifyContent: "space-between",
        })}>
        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          {/* import MRT sub-components */}
          <MRT_ToggleFullScreenButton table={table} />
          <MRT_ShowHideColumnsButton table={table} />
          <MRT_ToggleGlobalFilterButton table={table} />
          <MRT_GlobalFilterTextField table={table} />
        </Box>
        <Box>
          <Box sx={{ display: "flex", gap: "0.5rem" }}>
            <Button color="error" disabled={isDisableButton} variant="contained" onClick={handlePrintBill}>
              <i className="fas fa-print mx-1" /> طباعة بوليصة الشحن
            </Button>
            <Button color="success" disabled={isDisableButton || !isInvoiceCreationDisable} variant="contained">
              <CreateCollectionInvoiceModel
                selectedRows={table.getSelectedRowModel().flatRows.map((row) => row.original)}
                navigate={useNavigate()}
              />
            </Button>
            <Button color="warning" disabled={isDisableButton} onClick={handleSelect} variant="contained">
              <i className="fas fa-download mx-1" /> تصدير اكسل
            </Button>
            <Button color="info" disabled={isDisableButton} onClick={changeStatusDialog.onTrue} variant="contained">
              <i className="fas fa-check mx-1" /> تغير الحالة
            </Button>
            <Button color="secondary" disabled={isDisableButton} onClick={handleSelect} variant="contained">
              <i className="fas fa-user mx-1" /> تعين سائق
            </Button>
          </Box>
        </Box>
      </Box>

      {changeStatusDialog.value && (
        <ChangePackageStatusModal
          popover={changeStatusDialog}
          packageIds={selectedPackageIds}
          onChange={refreshTable}
        />
      )}
    </>
  );
};

export default PackageTableTopBar;
