/*eslint-disable*/
import { Link } from "react-router-dom";
// nodejs library to set properties for components

// reactstrap components
import {
  Col,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Media,
  Nav,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import UtilityService from "service/utility";

const MobileNavigation = ({ collapseOpen, adminRoutes, routes, logo, toggleCollapse, createLinks }) => {
  return (
    <>
      <Nav className="align-items-center d-md-none">
        <UncontrolledDropdown nav>
          <DropdownToggle nav>
            <Media className="align-items-center">
              <span className="avatar avatar-sm rounded-circle">
                <img alt="..." src={require("../../assets/img/theme/team-1-800x800.jpg")} />
              </span>
            </Media>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem className="noti-title" header tag="div">
              <h6 className="text-overflow m-0">Welcome!</h6>
            </DropdownItem>
            <DropdownItem to="/user-profile" tag={Link}>
              <i className="ni ni-single-02" />
              <span>My profile</span>
            </DropdownItem>
            <DropdownItem to="/user-profile" tag={Link}>
              <i className="ni ni-settings-gear-65" />
              <span>Settings</span>
            </DropdownItem>
            <DropdownItem to="/user-profile" tag={Link}>
              <i className="ni ni-calendar-grid-58" />
              <span>Activity</span>
            </DropdownItem>
            <DropdownItem to="/user-profile" tag={Link}>
              <i className="ni ni-support-16" />
              <span>Support</span>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
              <i className="ni ni-user-run" />
              <span>Logout</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
      {/* Collapse */}
      <Collapse navbar isOpen={collapseOpen}>
        {/* Collapse header */}
        <div className="navbar-collapse-header d-md-none">
          <Row>
            {logo ? (
              <Col className="collapse-brand" xs="6">
                {logo.innerLink ? (
                  <Link to={logo.innerLink}>
                    <img alt={logo.imgAlt} src={logo.imgSrc} />
                  </Link>
                ) : (
                  <a href={logo.outterLink}>
                    <img alt={logo.imgAlt} src={logo.imgSrc} />
                  </a>
                )}
              </Col>
            ) : null}
            <Col className="collapse-close" xs="6">
              <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
                <span />
                <span />
              </button>
            </Col>
          </Row>
        </div>
        {/* Form */}
        <Form className="mt-4 mb-3 d-md-none">
          <InputGroup className="input-group-rounded input-group-merge">
            <Input
              aria-label="Search"
              className="form-control-rounded form-control-prepended"
              placeholder="Search"
              type="search"
            />
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <span className="fa fa-search" />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Form>
        {/* Navigation */}
        <Nav navbar>{createLinks(routes)}</Nav>
        {UtilityService.isAdmin() && (
          <>
            <hr className="my-3" />
            <Nav navbar>{createLinks(adminRoutes)}</Nav>
          </>
        )}
      </Collapse>
    </>
  );
};

export default MobileNavigation;
