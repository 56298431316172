import React from "react";
import SearchBox from "components/Search/SearchBox";
import NotificationBox from "components/Notification/NotificationBox";
import ChatBox from "components/Chat/ChatBox";
import UserAvatar from "components/UserAvatar";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import UserService from "service/user";
import moment from "moment";
import CreateNewPackageModal from "components/Modals/CreateNewPackage";

const AdminNavbar = (props) => {
  const navigate = useNavigate();

  const handleSubmitLogout = (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/auth/login");
  };

  const showCaht = false;
  const currentUser = UserService.getCurrentUser();

  const resolvePageClassName = (path) => {
    let className = "";
    if (path) {
      className = path.substring(1).replace(/\//g, "-");
    }
    return className;
  };

  const lastLogin = currentUser?.lastLogin || null;

  return (
    <>
      <Navbar
        className={`navbar-top bg-primary d-none d-md-flex ${resolvePageClassName(props?.location?.pathname)}`}
        expand="md"
        id="navbar-main">
        <Container fluid>
          <SearchBox />

          <CreateNewPackageModal className="mx-2" onPackageCreated={() => {}} />

          <NotificationBox />

          {showCaht && <ChatBox />}

          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <UserAvatar photo={currentUser.photo} bgColor="white" textColor="primary" />
                  <Media className="mr-2 d-none d-lg-block">
                    <div>
                      <span className="mb-0 text-sm font-weight-bold nav-user-name">
                        {currentUser.firstName} {currentUser.middleName} {currentUser.lastName}
                      </span>
                    </div>
                    <span className="mb-0 text-sm font-weight-bold nav-user-text">
                      {lastLogin && "اخر دخول :" + moment(lastLogin).format("MMM Do, [at] H:mm A")}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">مرحبا بك!</h6>
                </DropdownItem>
                <DropdownItem to="/profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>الملف الشخصي</span>
                </DropdownItem>
                <DropdownItem to="/settings" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>الاعدادات</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#pablo" onClick={handleSubmitLogout}>
                  <i className="ni ni-user-run" />
                  <span>تسجيل خروج</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
