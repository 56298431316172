import { Card, CardContent, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const PackageStatisticWidget = ({ color, totalPackage, title, value, status }) => {
  const PackagesHistoryStatus = ["Z", "H", "J", "L"];

  const link = PackagesHistoryStatus.includes(status)
    ? "/packages/history?status=" + status
    : "/packages?status=" + status;

  return (
    <Card
      sx={{
        border: `1px solid ${color}`,
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        "&:hover": {
          transform: "scale(1.03)", // Scale up on hover
          boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.3)", // Stronger shadow on hover
        },
      }}>
      <CardContent style={{ padding: 0, width: "100%", heigth: "100%" }}>
        <Link to={link}>
          <Stack alignItems="center" style={{ padding: 5, paddingBottom: 5 }}>
            <Typography variant="h6" style={{ color: color, textAlign: "center" }}>
              {title}
            </Typography>
            <Typography variant="h6" style={{ color: color }}>
              {value} / {totalPackage}
            </Typography>
          </Stack>
        </Link>
      </CardContent>
    </Card>
  );
};

export default PackageStatisticWidget;
