import StatisticWidget from "components/Headers/StatisticWidget";
import { useEffect, useState } from "react";
import StatisticService from "service/statistic";
import UtilityService from "service/utility";
import Grid from "@mui/material/Grid";

const GeneralStatistic = (props) => {
  const [statisticData, setStatisticData] = useState(null);

  useEffect(() => {
    if (!statisticData) {
      StatisticService.getSummaryStatistic().then((res) => {
        setStatisticData(res.data);
      });
    }
  }, [props]);

  const items = statisticData && [
    {
      url: "/packages/history",
      title: "اجمالي الطرود",
      value: statisticData.totalPackages,
      icon: "fas fa-cubes",
      color: "#fe6f2f",
    },
    {
      url: "/packages",
      title: "الطرود النشطة",
      value: statisticData.totalActivePackages,
      icon: "fas fa-cubes",
      color: "#2dce89",
    },

    {
      url: "/collections",
      title: "اجمالي التحصيلات",
      value: UtilityService.formatPrice(statisticData.totalCollection),
      icon: "fas fa-money-bill",
      color: "#11cdef",
    },
    {
      url: "/collections",
      title: "تحصيلات قيد التنفيذ",
      value: UtilityService.formatPrice(statisticData.totalActiveCollection),
      icon: "fas fa-money-bill",
      color: "#f5365c",
    },
    {
      url: "/customers",
      title: "الزبائن",
      value: statisticData.totalCustomers,
      icon: "fas fa-users",
      color: "#212529",
    },
  ];

  return (
    <>
      {statisticData && (
        <Grid container spacing={3}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
              <StatisticWidget
                url={item.url}
                title={item.title}
                value={item.value}
                icon={item.icon}
                color={item.color}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default GeneralStatistic;
