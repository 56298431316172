import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PackageService from "service/package";
import CustomerInfo from "./CustomerInfo";
import DriverInfo from "./DriverInfo";
import PackageInfo from "./PackageInfo";
import TimeLine from "./TimeLine";
import { useNavigate } from "react-router-dom";
import UtilityService from "service/utility";
import OrganizationInfo from "./OrganizationInfo";
import { fPackageId } from "utils/format-number";

const PackageDetails = (props) => {
  const [packageDetail, setPackageDetails] = useState(null);
  const [error, setError] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isNaN(parseInt(id))) {
      PackageService.getPackageDetails(id)
        .then((res) => {
          setPackageDetails(res.data);
        })
        .catch((error) => {
          setError(true);
        });
    } else {
      navigate("/not-found");
    }
  }, [props]);

  const print = () => {
    window.open("/print/package/" + packageDetail.id, "_blank", "height=1000px,width=850");
  };

  return (
    <>
      <Container className="pt-2 pt-md-8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col d-flex justify-content-between align-items-center">
                    <h2 className="mb-0">{packageDetail ? `${fPackageId(packageDetail.id)}` : ""}</h2>
                    <div className="d-flex">
                      <i className="fas fa-download display-4 mx-3" role="button" />
                      <i className="fas fa-print display-4" role="button" onClick={print} />
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                {packageDetail && (
                  <Row className="m-0">
                    <Col lg="12" className="p-4">
                      <TimeLine lastStatus={packageDetail.status} data={packageDetail.timeline} />
                      <hr className="my-4" />
                      <PackageInfo package={packageDetail} />
                      <hr className="my-4" />
                      <OrganizationInfo organization={packageDetail.organization} />
                      <hr className="my-4" />
                      <CustomerInfo
                        address={packageDetail.address}
                        customerId={packageDetail.customer.id}
                        customerNote={packageDetail.customerNote}
                      />
                      <hr className="my-4" />
                      {packageDetail.driver && <DriverInfo driver={packageDetail.driver} car={packageDetail.car} />}
                    </Col>
                  </Row>
                )}
                {!packageDetail && error && (
                  <p className="p-3">لم يتم العثور على اي نتائج. يرجي التاكد من رقم الطرد.</p>
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PackageDetails;
