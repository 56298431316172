import { useEffect, useState } from "react";
import DriverService from "service/driver";

const DriverSelect = (props) => {
  const [driverId, setDriverId] = useState("");
  const [driversList, setDriversList] = useState([]);

  const handleChange = (e) => {
    setDriverId(e.target.value);
    if (props.onChange) {
      props.onChange(driversList.find((o) => o.userId == e.target.value));
    }
  };

  useEffect(() => {
    DriverService.getAllDriversList().then((res) => {
      setDriversList(res.data);
      setDriverId(props.driverId);
    });
  }, []);

  return (
    <select
      type="text"
      value={driverId}
      onChange={handleChange}
      className={`form-control ${props.showError && !driverId ? "is-invalid" : ""} ${props.inline ? "mr-2" : ""}`}>
      <option value=""></option>
      {driversList
        .filter((d) => d.status)
        .map((driver) => {
          return (
            <option key={driver.userId} value={driver.userId}>
              {driver.userName} - {driver.firstName} {driver.middleName} {driver.lastName} ({driver.mobile})
            </option>
          );
        })}
    </select>
  );
};

export default DriverSelect;
