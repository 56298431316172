import { PackageAPI } from "api/package";

const PackageService = {
  createNewPackage: function (data) {
    return PackageAPI.createNewPackage(data);
  },

  updatePackage: function (packageId, data) {
    return PackageAPI.updatePackage(packageId, data);
  },

  printBill: function (packageIds) {
    return PackageAPI.printBill(packageIds);
  },

  getActivePackagesList: function () {
    return PackageAPI.getActivePackagesList();
  },
  getPackagesList: function () {
    return PackageAPI.getPackagesList();
  },
  getPackageHistoryList: function (filters) {
    const params = {};
    Object.entries(filters).forEach(([key, value]) => {
      if (value !== null && value !== "") {
        params[key] = value;
      }
    });
    return PackageAPI.getPackageHistoryList(params);
  },
  addPackageNote: function (packageId, note) {
    return PackageAPI.updatePackage(packageId, { note: note });
  },
  updatePackageStatus: function (packageId, status) {
    return PackageAPI.updatePackage(packageId, { status: status });
  },
  updatePackagesStatus: function (packageIds, status) {
    return PackageAPI.updatePackagesStatus(packageIds, status);
  },
  getPackageDetails: function (packageId) {
    return PackageAPI.getPackageDetails(packageId);
  },
  assigneDriverToPackage: function (packageId, driverId) {
    return PackageAPI.assigneDriverToPackage(packageId, { driverId });
  },
  linkPackageToExternalSystem: function (packageId, data) {
    return PackageAPI.linkPackageToExternalSystem(packageId, data);
  },
  getPackageSummary: function () {
    return PackageAPI.getPackageSummary();
  },
  updatePackageCost: function (packageId, cost) {
    return PackageAPI.updatePackage(packageId, { cost: cost });
  },
  updatePackageTotalCollection: function (packageId, totalCollection) {
    return PackageAPI.updatePackage(packageId, { totalCollection: totalCollection });
  },
  updatePackageDeliveryDate: function (packageId, data) {
    return PackageAPI.updatePackage(packageId, data);
  },
};

export default PackageService;
