import SendIcon from "@mui/icons-material/Send";
import { Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState } from "react";

const Actions = ({ onCancel, errorMessage, style }) => {
  const [checked, setChecked] = useState(false);

  return (
    <Stack style={style}>
      <Typography color="error">{errorMessage}</Typography>

      <Stack spacing={2}>
        <FormControlLabel
          style={{ margin: 0 }}
          control={<Checkbox checked={checked} onChange={(event) => setChecked(event.target.checked)} />}
          label="أوافق على أن الشركة غير مسؤولة عن محتوى و / أو طبيعة الطرد."
        />

        <Button color="primary" type="submit" variant="contained" disabled={!checked}>
          <SendIcon style={{ marginRight: 3, marginLeft: 3 }} /> ارسال الطلب
        </Button>

        <Button color="secondary" variant="outlined" data-dismiss="modal" onClick={onCancel}>
          الغاء
        </Button>
      </Stack>
    </Stack>
  );
};

export default Actions;
