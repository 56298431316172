import UtilityService from "service/utility";
import { PACKAGE_STATUS_LIST } from "variables/constant";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

const PackageStatusSelect = ({ onChange }) => {
  return (
    <TextField
      select
      fullWidth
      size="small"
      label="الحالة"
      defaultValue=""
      onChange={(event) => onChange(event.target.value)}>
      {PACKAGE_STATUS_LIST.map((status) => (
        <MenuItem key={status} value={status}>
          {UtilityService.renderStatusMessage("PACKAGE", status, false, true)}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default PackageStatusSelect;
