import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import PackageStatusSelect from "components/Package/PackageStatusSelect";
import React, { useState } from "react";
import PackageService from "service/package";
import TextField from "@mui/material/TextField";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChangePackageStatusModal = ({ popover, onChange, packageIds }) => {
  const [status, setStatus] = useState(null);
  const [error, setError] = useState("");

  const onSubmit = () => {
    setError("");

    if (packageIds && packageIds.length > 0) {
      return PackageService.updatePackagesStatus(packageIds, status)
        .then((res) => {
          onChange();
          popover.onFalse();
        })
        .catch((error) => {
          setError("ناسف! خطا خلال تعديل حالة الطرود. يرجى المحاولة مرة اخرى");
        });
    }
  };

  return (
    <Dialog
      open={popover.value}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth="sm"
      onClose={popover.onFalse}>
      <DialogTitle>تغير حالة الطرد / الطرود</DialogTitle>
      <DialogContent>
        <Stack sx={{ marginTop: 1 }}>
          <PackageStatusSelect onChange={setStatus} />

          <Typography sx={{ mt: 2 }} color="error">
            {error}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} sx={{ gap: 1, mb: 1 }}>
          <Button variant="outlined" onClick={popover.onFalse}>
            الغاء
          </Button>
          <Button variant="contained" onClick={onSubmit}>
            حفظ
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePackageStatusModal;
