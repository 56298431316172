import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { Autocomplete, TextField, Button } from "@mui/material";
import AddressService from "service/address";
import UtilityService from "service/utility";

const AreaSearch = () => {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const values = watch();

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [areaOptions, setAreaOptions] = useState(null);
  const [search, setSearch] = useState("");

  // Handle input change to fetch API results
  const handleInputChange = async (event, value) => {
    if (!value) {
      setOptions([]);
    }

    setSearch(value);

    setLoading(true);
    if (value) {
      try {
        // Replace this with your actual API
        const response = await AddressService.searchArea(value, 5, 0);

        // Assuming API returns an array of objects with a `label` property
        setAreaOptions(response.data);
        setOptions(
          response.data.map((area) => ({
            label: `${area.arabicName} - ${UtilityService.renderCity(area.city)} - ${UtilityService.renderState(
              area.state
            )}`,
            id: area.code,
          })) || []
        );
      } catch (error) {
        console.error("Error fetching options:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleAreaSelect = (event, value) => {
    if (value) {
      setValue("areaCode", value.id);
    } else {
      setValue("areaCode", null);
    }
  };

  const renderValue = () => {
    if (values && values.areaCode) {
      const area = areaOptions.find((a) => a.code == values.areaCode);
      if (area) {
        return `${area.arabicName} - ${UtilityService.renderCity(area.city)} - ${UtilityService.renderState(
          area.state
        )}`;
      }
    }
    return search;
  };

  return (
    <Autocomplete
      inputValue={renderValue()}
      options={options}
      loading={loading}
      getOptionLabel={(option) => option.label || ""}
      onInputChange={(_, value) => {
        handleInputChange(_, value); // Fetch API data
      }}
      onChange={handleAreaSelect}
      renderOption={(props, option) => (
        <li
          {...props}
          style={{
            "&:hover": {
              backgroundColor: "lightgray",
            },
            padding: "8px", // Add padding to each option
            fontWeight: "bold", // Example: bold text
            fontSize: 12,
          }}>
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!errors.areaCode}
          label="عنوان المستلم"
          variant="outlined"
          size="small"
          fullWidth
          margin="normal"
          InputProps={{
            ...params.InputProps,
            endAdornment: params.InputProps.endAdornment,
          }}
        />
      )}
    />
  );
};

export default AreaSearch;
