import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import UtilityService from "service/utility";
import { fPackageId } from "utils/format-number";

const PackagesResult = (props) => {
  const { packages } = props;

  return (
    <Col lg="3">
      <h2 className="mb-3">
        <span className="pb-1 border-bottom border-primary">الطرود</span>
      </h2>
      <Row>
        {packages.length === 0 && <Col lg="12">ناسف! لم يتم العثور عل اي نتائج.</Col>}
        {packages.map((p) => (
          <Col lg="12" className="mb-4 d-flex">
            <i className="fas fa-cubes display-4 ml-3"></i>
            <div>
              <p className="m-0">
                <Link to={`/packages/${p.id}`} className="m-0 text-dark text-underline">
                  {fPackageId(p.id)} {UtilityService.renderStatusMessage("PACKAGE", p.status)}
                </Link>
              </p>
              <p className="m-0 badge">
                {p.address?.firstName} {p.address?.middleName} {p.address?.lastName} - {p.address?.mobile}
              </p>
              <p className="m-0">{UtilityService.formatAddress(p.address)}</p>
            </div>
          </Col>
        ))}
      </Row>
      <div className="mt-1">
        <Link className="text-dark text-underline" to="/packages">
          جميع الطرود...
        </Link>
      </div>
    </Col>
  );
};

export default PackagesResult;
